p {
  margin: 5px !important;
}
.App {
  display: flex;
  flex-direction: column;
  height: 95vh;
}
.mapBody {
  flex: 1;
}
.mapContainer {
  height: 100%;
}

.btnCont {
  display: flex;
  justify-content: center;
}

.logoImage{
  height: 40px;
}

.ima   
{
  display:block;
  height: 40px;
  margin-left:auto;
  margin-right:auto;
}

.header {
   display: flex;
   justify-content: center;
   margin-top:5px;
   margin-bottom: 5px;
}



.service-div h1{
  font-size: 18px;
  font-weight: normal;
}

.service-div span{
  font-size: 16px;
  font-weight: normal;
}

.header img.logo {
  font-size: 25px;
  font-weight: bold;
}

.header img:hover {
  background-color: #ddd;
  color: black;
}

.header img.active {
  background-color: dodgerblue;
  color: white;
}

.header-right {
  float: right;
}

  .metadata
  {
    border:0px solid whitesmoke;
    border-bottom: thin solid grey;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-size:0.75em;
    margin-left:5%;
    margin-right:5%;

  }
  
  a:link{
    text-decoration: none;
  }
  .data{
    margin-top: 2%;
    padding: 1%;
    background-color: #095FF0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: 0 auto;
    bottom: 0%;
    width: 50%;
  }
  .data2
  {
    color:#FAE823;
  }
  .meta{
    font-size: 0.75em;;
  }
  .s1
  {
    text-align: left;
  }
  .s2
  {
    text-align: right;
  }
  .route
  {
    text-align: center;
  }
  .s3
  {
    font-weight: bold;
  }